<template>
	<div class="intro wrap">
		<h2>
			<strong>
				Hi there!
			</strong>
			<span>
				Designing and developing usable and good looking websites has become my passion. I love creating simple, unique, stylish and easy-to-use web experiences.
			</span>
		</h2>
	</div>
</template>

<style lang="scss" scoped>
	h2 {
		line-height: 1;
		text-align: left;

		strong {
			font-weight: 800;
		}

		span {
			font-size: 0.625em;
			font-weight: 300;
		}
	}
</style>
